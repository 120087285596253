@keyframes scroll {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0)
  }
}

.swiper {
  height: 340px;
}

.swiper-pagination {
  bottom: 0 !important;
  z-index: 100 !important;
}

.extra-padding {
  padding-left: 16px;
}

.task-item-last {
  padding: 12px 0 0 !important;
}

.main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: loyal 0.5s ease-in-out;
  color: #373737;

  .events_item {
    margin: 0 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 200px !important;
    min-height: 200px !important;
    width: 80%;

    &_item_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 !important;
      height: 100%;
      max-height: 140px !important;
    }

    img {
      margin: 0 !important;
      display: flex;
      align-self: center;
      justify-self: center;
      max-height: 100% !important;
      height: 100%;
      object-fit: contain;
    }
  }

  &_video_wrapper {
    width: 100% !important;
    padding: 40px 20px 0;

    iframe {
      min-height: 650px;
    }

    div {
      padding: 20px 0;
      font-weight: 700;
    }
  }

  &_speech {
    margin: 20px 0 0;
    width: 100%;
    height: 300px;
    color: #fff;

    font-weight: 700;
    position: relative;
    //border-top: 1px solid #ccc;
    padding: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    &:after {
      position: absolute;
      top: 0; left: 0; bottom: 0; right: 0;
      content: "";
      box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.75);
    }

    p {
      display: flex;
      font-size: 24px;
      width: 100%;
      text-shadow: 1px 1px 3px #000000;
    }

    img {
      z-index: -5;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      filter: blur(3px);
    }

    span {
      font-size: 18px;
      font-weight: 700;
      position: absolute;
      bottom: 12px;
      text-shadow: 1px 1px 3px #000000;
      right: 20px;
    }
  }

  &_partnership {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 64px;

    .swiper {
      max-width: 100%;
    }

    &_title {
      font-size: 20px;
      font-weight: 700;
      margin: 20px 0;
    }

    &_item {
      height: 300px;
      width: 100%;
      border: 3px solid #7A8CC7;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: center;
    }

    img {
      width: 90px;
    }

    p {
      font-size: 14px;
      width: 80%;
      text-align: center;
      color: #434343;
      font-weight: 500;
      padding-top: 12px;
    }
  }

  &_ustav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    width: 100%;

    img {
      width: 40%;
    }

    &_title {
      font-size: 20px;
      font-weight: 700;
      margin: 20px 0;
    }
  }

  &_intro {
    padding: 0 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    &_team_main {
      margin: 20px 20px;
      display: flex;
      flex-direction: row;

      img {
        height: 74px !important;
      }

      div:first-child {
        width: 64px;
      }

      div:last-child {
        padding-left: 16px;
      }
    }

    &_team_group_content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 68px;
      height: 72px;
    }

    &_team_group_title {
      margin-top: 20px;
      text-align: center;
      padding: 0 0 4px;
      font-size: 18px;
      font-weight: 700;
    }

    &_team_group {
      width: 100%;
      padding: 8px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 16px 12px !important;
      font-weight: 700;
      font-style: italic;
    }

    &_team_item {
      width: 50%;
      margin: 20px 0;
      padding-left: 100px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;

      div:first-child {
        width: 72px;
      }

      div:last-child {
        padding-left: 16px;
      }
    }
  }

  &_relationships {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding: 12px 64px 32px;

    &_content {
      height: 100%;
      width: 100%;
    }

    &_title {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      margin: 60px 0 20px;
    }

    &_item {
      height: 300px;
      width: 100%;
      border: 3px solid #7A8CC7;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: center;

      p {
        padding: 0;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-self: center;
        font-size: 14px;
        color: #434343;
        text-align: center;
        max-width: 100%;
      }

      img {
        width: 104px
      }
    }

    img {
      transition: 0.5s;
    }

  }

  &_meetings {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_all {
      align-self: flex-end;
      justify-self: flex-end;
      margin: 0 24px;

      cursor: pointer;
      transition: 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }

    .last-meeting {
      width: 100%;
    }

    &_title {
      margin: 40px 0 20px;
      font-size: 20px;
      font-weight: 700;
    }
  }

  &_meetings-etc {
    overflow: hidden;
    font-size: 16.7px;
    font-weight: 400;
    padding: 4px 12px;
    list-style: none;
    text-align: justify;

    li {
      margin: 4px 12px;
      padding-bottom: 4px;
      border-bottom: 1px solid #ccc;

      &:last-child {
        border: none;
      }
    }
  }

  &_meetings_item {
    padding: 10px;
    width: 100%;
    cursor: pointer;
    position: relative;

    img {
      border-radius: 5px;
    }

    p {
      padding: 24px;
      left: 0;
      bottom: 12px;
      font-size: 24px;
      text-shadow: 2px 2px 1px #000;
      position: absolute;
      z-index: 10;
      color: #fff;
    }
  }

  &_about {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: justify;
    padding: 0 64px 24px;
    font-size: 16px;

    &_title {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      margin: 12px 0 24px;
    }

    &_text {
      padding-top: 40px;
      width: 65%;
      font-size: 18px;
    }

    &_lastNews {
      margin-left: auto;
      width: 30%;

      &_title {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 18px;
        text-align: right;
      }

      &_item {
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid #41558c;
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;

        &:hover {
          opacity: 0.7;
        }

        &_content {
          width: 100%;

          &_title {
            padding: 8px;
          }

          img {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }

  }

  &_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 1200px;
    background-color: #FFF;
    padding: 30px 0 20px;
    min-height: 100vh;
    height: 100%;
    box-shadow: 0 0 5px 0 #5e5e5e;
    border-radius: 5px;
    overflow: hidden;

    .line {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 10px;
      background-color: #7A8DC7;
    }

    &_logo {
      width: 18%;

      img {
        background-color: #fff;
        width: 100%
      }
    }
  }
}

.list-last {
  border-bottom: 1px solid #ccc;
}

@media (max-width: 1400px) {
  .main {
    padding: 0 20px;
    &_wrapper {
      width: 1120px;
    }
  }
}

@media (max-width: 1220px) {
  .main_meetings {
    flex-direction: column;

    p {
      font-size: 24px;
    }

    img {
      width: 100%;
    }
  }

  .main {
    padding: 0 20px;
  }
}

@media (max-width: 1024px) {
  .main_intro_team_item {
    padding-left: 60px;
  }

  .main_intro {
    flex-direction: column;

    &_video {
      text-align: justify;
      width: 100%;
    }

    &_team {
      margin-top: 40px;
      width: 100%;
    }
  }
}

@media (max-width: 900px) {
  .main_about {
    padding: 20px 24px;
    flex-direction: column;

    &_text {
      width: 100%;
    }

    &_lastNews {
      margin-top: 32px;
      width: 100%;
    }
  }

  .main_relationships {
    width: 100%;
    flex-direction: column;

    &_item {
      width: 70%;
    }
  }

  .main {
    &_partnership {
      &_title {
        padding: 0 12px;
        text-align: center;
      }
    }
  }
}

@media (max-width: 768px) {
  .main_intro_team_item {
    padding-left: 40px;
  }

  .main_meetings {
    &_title {
      margin: 20px 0;
    }

    &_item {
      width: 100%;

      p {
        font-size: 16px;
      }
    }
  }

  .main_speech {
    height: 240px;

    p {
      font-size: 12px;
    }

    span {
      right: 50%;
      transform: translateX(50%);
      font-size: 12px;
    }
  }
}

@media (max-width: 600px) {
  .main_relationships_item img {
    object-fit: contain !important;
  }

  .main_relationships_item p {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .main {
    &_ustav {
      img {
        width: 50%;
      }
    }

    &_partnership {
      flex-direction: column;

      &_item {
        margin-bottom: 16px;
      }

      &_title {
        font-size: 16px;
        padding: 0 12px;
        text-align: center;
      }
    }

    &_meetings {
      &_title {
        font-size: 16px;
        padding: 0 12px;
        text-align: center;
      }
    }
  }

  .main_relationships {
    width: 100%;
    flex-direction: column;

    &_item {
      width: 100%;

      p {
        font-size: 16px;
      }

      img {
        height: 100%;
      }
    }
  }
}

@media (max-width: 500px) {
  .main_intro_team_item {
    padding-left: 12px;
    align-items: center;
  }

  .main {
    padding: 0 10px;

    &_video_wrapper {
      width: 100% !important;
      padding: 40px 20px 0;

      iframe {
        min-height: 280px;
      }
    }
  }

  .main_intro_team_group_content {
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }

  .main_intro_team_item {
    width: 80% !important;
  }

  .main_intro_team_main {
    width: 80% !important;
  }
}

@media (max-width: 425px) {
  .main_about {
    font-size: 14px;

    p:first-child {
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      margin: 0 0 20px;
    }
  }

  .main {
    padding: 0;

    &_intro {
      &_team_group {

        .main_intro_team_main {
          div:first-child {
            width: 68px;
          }

          img {
            width: 68px;
            height: 72px !important;
          }
        }
      }
    }
  }
}
