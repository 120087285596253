@keyframes loyal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.register {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: loyal 0.5s ease-in-out;

  &_extra {
    padding-top: 8px;
    width: 100%;
    text-align: right;
    font-size: 13px;
    color: #585858;
  }

  &_btn {
    width: 100%;
    border: none;
    outline: none;
    background-color: #7A8DC7;
    color: #fff;
    font-size: 16px;
    height: 40px;
    margin-top: 16px;
    border-radius: 5px;
    transition: .3s all;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: .5;
    }

    &:active {
      background-color: #453C8C;
    }
  }

  &_additional {

    label {
      font-size: 14px;
    }

    textarea {
      border-radius: 3px;
      outline: none;
      margin-top: 8px;
      padding: 4px;
      resize: none;
      height: 200px;
      border: 2px solid #7A8DC7;
      width: 100%;
    }

    textarea:focus {
      border: 2px solid #453C8C;
    }
  }

  &_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 1200px;
    background-color: #fff;
    padding: 30px 0 20px;
    height: 100%;
    box-shadow: 0 0 5px 0 #5e5e5e;
    border-radius: 5px;
    overflow: hidden;
  }

  &_title {
    padding: 0 0 20px;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
  }

  &_form {
    padding: 20px 16%;
    width: 100%;
  }

  &_form-group {
    position: relative;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &_item {
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;

      &_error {
        bottom: 0;
        position: absolute;
        font-size: 12px;
        color: red;
      }

      input {
        transition: all 0.3s;
        border: none;
        outline: none;
        width: 320px;
        border-bottom: 2px solid #7A8DC7;
        padding: 4px 6px;
      }

      input:focus {
        border-bottom: 2px solid #453C8C;
      }

      label {
        font-size: 14px;
        padding-bottom: 8px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .register {
    padding: 0 20px;
    &_wrapper {
      width: 1120px;
    }
  }
}

@media (max-width: 1220px) {
  .register {
    padding: 0 20px;
  }
}

@media (max-width: 1024px) {
  .register {
    padding: 0 20px;
  }

  .register_form-group {
    flex-direction: column;

    &_item {
      width: 100%;
    }

    input {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .register {
    padding: 0 10px;
  }
}

@media (max-width: 425px) {
  .register {
    padding: 0;

    &_form {
      padding: 12px 36px;
    }
  }
}