.contacts {
  margin: 12px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 1px 1px 1px #717171;

  &_title {
    font-weight: 700;
    padding: 12px 0;
    border-bottom: 1px solid #fff;
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 120px;
    padding: 40px 48px;
    width: 1200px;
    background-color: #41558c;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 0 5px 0 #5e5e5e;
  }

  &_info {
    padding: 24px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.right-alignment {
  text-align: right;
}

@media (max-width: 1400px) {
  .contacts {
    padding: 0 20px;
    &_wrapper {
      width: 1120px;
    }
  }
}

@media (max-width: 1220px) {
  .contacts {
    padding: 0 20px;

    &_wrapper {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .contacts {
    padding: 0 10px;


    &_title {
      text-align: center;
    }

    &_wrapper {
      font-size: 14px;
      padding: 10px 16px;
    }
  }
}

@media (max-width: 425px) {
  .contacts {
    padding: 0;

    &_info {
      font-size: 12px;
    }

    &_wrapper {
      padding: 10px 16px;
    }
  }
}