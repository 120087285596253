.ustav {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: loyal 0.5s ease-in-out;
  color: #373737;

  &_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 1200px;
    background-color: #FFF;
    padding: 30px 0 20px;
    min-height: 100vh;
    height: 100%;
    box-shadow: 0 0 5px 0 #5e5e5e;
    border-radius: 5px;
    overflow: hidden;
  }
}

@media (max-width: 600px) {
  .main_ustav {
    flex-direction: column;

    img {
      width: 100%;
    }
  }
}