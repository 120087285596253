.associations {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: loyal 0.5s ease-in-out;

  &_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 1200px;
    background-color: #fff;
    padding: 30px 0 20px;
    min-height: 100vh;
    height: 100%;
    box-shadow: 0 0 5px 0 #5e5e5e;
    border-radius: 5px;
    overflow: hidden;
  }

  &_title {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #ccc;
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 20px;
  }

  &_container {
    padding: 0 24px;
    width: 100%;
    display: flex;
    flex-direction: column;

  }

  &_relationships_item {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 140px;
    border-bottom: 1px solid #ccc;
    padding: 16px 8px;

    img {
      width: 200px;
    }

    &--link {
      text-decoration: underline;
      font-size: 14px;
      color: #3B3082;
    }

    &--text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 24px;

      div span {
        font-weight: 700;
        border-bottom: 1px solid #ccc;
      }

      div p {
        padding-top: 8px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .associations {
    padding: 0 20px;
    &_wrapper {
      width: 1120px;
    }
  }
}

@media (max-width: 600px) {
  .associations_relationships_item img {
    width: 100px;
  }

  .associations_relationships_item--link {
    padding-top: 8px;
  }
}

@media (max-width: 450px) {
  .associations_container {
    padding: 0 18px;
  }

  .associations_relationships_item {
    padding: 12px 0;
  }

  .associations_relationships_item img {
    width: 70px;
  }
}

@media (max-width: 500px) {
  .associations {
    padding: 0 10px;
  }
}

@media (max-width: 425px) {
  .associations {
    padding: 0;
  }
}