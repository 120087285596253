.student-page {
  position: relative;
  padding: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: loyal 0.5s ease-in-out;

  &_images {
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    img {
      padding: 10px;
      width: 25%;
      height: 100%;
    }
  }

  &_title {
    padding: 40px 0 20px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #ccc;
    font-size: 20px;
    font-weight: 700;
  }

  &_link-back {
    position: absolute;
    top: 20px;
    left: 20px;
    border: 1px solid #453C8C;
    padding: 4px 12px;
    border-radius: 5px;
    transition: .2s;
  }

  &_link-back:hover {
    cursor: pointer;
    background: #453C8C;
    color: #fff;
    transition: .3s;
  }

  &_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 1200px;
    background-color: #fff;
    padding: 30px 0 20px;
    min-height: 100vh;
    height: 100%;
    box-shadow: 0 0 5px 0 #5e5e5e;
    border-radius: 5px;
    overflow: hidden;
  }

  &_texts {
    width: 100%;
    text-align: justify;
    padding: 20px 48px;
    display: flex;
    flex-direction: column;

    div {
      padding: 12px 0;
    }
  }
}

@media (max-width: 600px) {
  .event-page {
    &_images {
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      img {
        padding: 10px;
        width: 100%;
        height: 100%;
      }
    }
  }
}