.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &_wrapper {
    display: flex;
    position: relative;
    width: 1200px;
    box-shadow: 0 0 5px 0 #5e5e5e;
    margin-bottom: 12px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #fff;

    &_back {
      padding: 10px 0 0;
      background-repeat: no-repeat;
      background-position: 100% -14px;
      background-size: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &_logo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 24px;

      img {
        width: 18%;
      }

      &_speech {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        padding: 12px 24px;
        text-align: center;

        p {
          font-family: 'Nunito+Sans', serif;
        }

        span {
          font-family: 'Nunito+Sans', serif;
          padding: 24px 0 0;
          text-align: right;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }

  &_nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px 0 0;
    text-shadow: 1px 1px 1px #313131;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 13px;

    span {
      padding-left: 8px;
    }

    &_link.active {
      background-color: #334474 !important;
    }

    &_link:active {
      opacity: 0.9;
    }

    &_link:first-child {
      border-bottom-left-radius: 5px;
    }

    &_link:last-child {
      border-bottom-right-radius: 5px;
    }


    &_link {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #41558c;
      border-left: 1px solid #453C8C;
      width: 33.3333333%;
      text-align: center;
      line-height: 40px;
      color: #fff;
      height: 36px;
      transition: all .3s;

      &:first-child {
        border: none;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
}

@media (max-width: 1400px) {
  .header {
    padding: 0 20px;
    &_wrapper {
      width: 1120px;
    }
  }
}


@media (max-width: 1200px) {
  .header {
    padding: 0 20px;
    &_wrapper {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .header {
    &_nav {
      flex-wrap: wrap;

      a:last-child {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top: 1px solid #334474;
        width: 100%;
      }

      &_link:first-child {
        border-bottom-left-radius: 0;
      }

      &_link:last-child {
        border-bottom-right-radius: 0;
      }

    }
  }
}

@media (max-width: 500px) {
  .header_wrapper_logo img {
    width: 25%;
  }

  .header_wrapper_logo_speech {
    font-size: 14px;

    span {
      font-size: 14px;
    }
  }

  .header {
    padding: 0 10px;

    &_nav {
      font-size: 12px;
    }
  }
}

@media (max-width: 425px) {
  .header_wrapper_logo img {
    width: 32%;
  }

  .header_wrapper_logo_speech {
    font-size: 14px;

    span {
      font-size: 12px;
    }
  }

  .header {
    padding: 0;

    &_nav {
      font-size: 11px;
    }
  }
}