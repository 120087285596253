.swiper-pagination-bullet {
  width: 9px !important;
  height: 9px !important;
}

.swiper-pagination {
  pointer-events: all !important;
}

.events {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: loyal 0.5s ease-in-out;

  &_categories {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 12px 48px 0;

    &_item.selected_category {
      background: #41558c;
      color: #FFFFFF;
      transition: 0.4s;
    }

    &_item {
      margin-bottom: 12px;
      margin-right: 12px;
      border: 1px solid #41558c;
      border-radius: 50px;
      padding: 6px 12px;
      color: #434343;
      font-size: 14px;
      font-weight: 500;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  &_item-title {
    transition: all 0.3s;
    opacity: 1;
    height: 50px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    overflow: hidden;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: #434343;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding: 12px 20px 0;

    span {
      transition: 0.2s;
      opacity: 1;
    }
  }

  &_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 1200px;
    background-color: #fff;
    padding: 30px 0 20px;
    min-height: 100vh;
    height: 100%;
    box-shadow: 0 0 5px 0 #5e5e5e;
    border-radius: 5px;
    overflow: hidden;
  }

  &_title {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #ccc;
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 20px;
  }

  &_container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 12px 48px;
  }

  &_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FFFFFF;
    box-shadow: 1px 1px 8px 0 #ccc;
    border-radius: 5px;
    width: 32%;
    margin: 20px 14px 20px 0;
    max-height: 290px;
    min-height: 290px;
    transition: all 0.3s;
    border: 1px solid #41558c;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }

    &_content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 290px;
      padding: 8px;

      &_text {
        color: #373737;
        padding: 0 12px;
        font-size: 14px;
        text-align: justify;

        div {
          margin-top: 40px;
        }

        a {
          bottom: 12px;
          right: 12px;
          position: absolute;
          color: #7A8DC7;
          font-size: 14px;
          transition: .3s;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-height: 200px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .events {
    padding: 0 20px;
    &_wrapper {
      width: 1120px;
    }
  }
}

@media (max-width: 1220px) {
  .events {
    padding: 0 20px;
  }
}

@media (max-width: 1024px) {
  .events {

    &_item {
      width: 49%;
    }
  }
}

@media (max-width: 600px) {
  .events {

    &_item {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .events {
    padding: 0 10px;
  }
}

@media (max-width: 425px) {
  .events {
    padding: 0;
  }
}