.mavvuz {
  position: relative;

  &_bg {
    width: 64px;
    position: fixed;
  }

  .bg-left {
    left: calc((100% - 1200px) / 4);
    transform: translateX(-50%);
  }

  .bg-right {
    right: calc((100% - 1200px) / 4);
    transform: translateX(50%);
  }
}

@media (max-width: 1400px) {
  .mavvuz {
    .bg-left {
      left: calc((100% - 1140px) / 4);
      transform: translateX(-50%);
    }

    .bg-right {
      right: calc((100% - 1140px) / 4);
      transform: translateX(50%);
    }
  }
}

@media (max-width: 1200px) {
  .mavvuz_bg {
    display: none;
  }
}