.partnership {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: loyal 0.5s ease-in-out;

  &_item-title {
    transition: all 0.3s;
    opacity: 1;
    height: fit-content;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    top: 0;
    left: 0;
    color: #fff;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-shadow: 2px 1px 3px #000000;
    padding: 12px;

    span {
      transition: 0.2s;
      opacity: 1;
    }
  }

  &_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 1200px;
    background-color: #fff;
    padding: 30px 0 20px;
    min-height: 100vh;
    height: 100%;
    box-shadow: 0 0 5px 0 #5e5e5e;
    border-radius: 5px;
    overflow: hidden;
  }

  &_title {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #ccc;
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 20px;
  }

  &_container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
  }

  &_item {
    background-color: #fcfcfc;
    box-shadow: 1px 1px 8px 0 #ccc;
    border-radius: 5px;
    margin: 20px 0;
    width: 32%;
    position: relative;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;

    &_content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      &_text {
        color: #373737;
        padding: 20px 12px;
        font-size: 14px;
        text-align: justify;

        div {
          margin-top: 40px;
        }

        a {
          bottom: 20px;
          right: 12px;
          position: absolute;
          color: #7A8DC7;
          font-size: 14px;
          transition: .3s;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      img {
        width: 100%;
        max-height: 245px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .partnership {
    padding: 0 20px;
    &_wrapper {
      width: 1120px;
    }
  }
}

@media (max-width: 1220px) {
  .partnership {
    padding: 0 20px;
  }
}

@media (max-width: 1024px) {
  .partnership {

    &_item {
      width: 49%;
    }
  }
}

@media (max-width: 600px) {
  .partnership {

    &_item {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .partnership {
    padding: 0 10px;
  }
}

@media (max-width: 425px) {
  .partnership {
    padding: 0;
  }
}